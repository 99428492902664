import { FC, ReactNode } from "react";
import { Loader } from "../icon/SvgIcon";

interface Props {
  onClick?: () => void;
}

const Loading: FC<Props> = ({ onClick }) => {
  return (
    <div className="block overflow-hidden">
      <div
        className={`fixed top-0 left-0 bottom-0 transition-all duration-700 ease-in-out backdrop-blur-sm bg-black/20 z-40 w-full h-screen overflow-hidden`}
        onClick={onClick}
      >
        <div className="flex items-center justify-center h-full">
          <div className="w-auto bg-white pt-7 pb-6 px-6 mx-auto rounded-3xl flex flex-col items-center space-y-[1.125rem]">
            <Loader size="40" className="fill-neutral-900 animate-spin" />
            <span className="font-medium text-lg leading-[1.5rem] text-neutral-500">
              Loading....
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
