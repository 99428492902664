import { FC, useEffect, useRef, useState } from "react";
import Icon from "../icon/Icon";

interface Props {
  value?: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  onChange?: (e: any) => void;
  onInput?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onBlur?: (e: any) => void;
  onClick?: (e: any) => void;
  required?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  invalidMessage?: string;
  innerRef: any;
  autoComplete?: string;
  defaultValue?: string;
  onClickIcon?: (e: any) => void;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  isIconClick?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  zIndex?: string;
  mode?: string;
  labelClassName?: string;
}

const iconPositionClasses = {
  left: "left-0 pl-4",
  right: "right-0 pr-4",
};

const Input: FC<Props> = ({
  value,
  name,
  type = "text",
  label = "Text Label",
  icon,
  iconPosition = "left",
  placeholder = "Input text",
  className = "",
  onChange,
  onInput,
  onKeyUp,
  onBlur,
  onClick,
  required = false,
  isValid = false,
  isInvalid = false,
  invalidMessage = "",
  innerRef,
  autoComplete = "on",
  defaultValue,
  onClickIcon,
  onFocus,
  autoFocus,
  isIconClick = false,
  readOnly,
  disabled = false,
  zIndex = "",
  mode,
  labelClassName = "",
}) => {
  const [isShow, setIsShow] = useState(false);
  let SpesificIcon;
  if (icon) {
    SpesificIcon = Icon[icon];
  } else if (type === "password") {
    if (isShow) {
      SpesificIcon = Icon["EyeSlash"];
    } else {
      SpesificIcon = Icon["Eye"];
    }
  }

  let valid = "";
  if (innerRef.current) {
    if (innerRef.current.value) {
      valid = "valid:px-4";
    }
  }

  return (
    <>
      <div className={`relative w-full md:w-auto ${zIndex}`}>
        {icon && type !== "password" && (
          <div
            className={`flex absolute inset-y-0 items-center ${
              iconPositionClasses[iconPosition]
            } ${isIconClick ? "cursor-pointer" : ""}`}
            onClick={onClickIcon}
            data-tour={mode !== "desktop" ? "step-radius" : ""}
          >
            <SpesificIcon />
          </div>
        )}

        {type === "password" && (
          <div
            className={`flex absolute inset-y-0 items-center cursor-pointer ${iconPositionClasses["right"]}`}
          >
            <SpesificIcon onClick={() => setIsShow(!isShow)} />
          </div>
        )}

        <input
          ref={innerRef}
          id={`input-${name}`}
          value={value}
          defaultValue={defaultValue}
          name={name}
          type={type !== "password" ? type : isShow ? "text" : "password"}
          className={`${className} block rounded-lg ${
            icon && iconPosition === "left" && "!pl-12"
          } pt-5 pb-3 px-4 w-full text-regular-none ${
            disabled || readOnly ? "text-neutral-500" : "text-neutral-900"
          } bg-white border border-neutral-300 appearance-none focus:pt-5 focus:pb-3 ${valid} focus:outline-none focus:border-neutral-300 focus:ring-0 peer placeholder-transparent ${
            !isValid &&
            isInvalid &&
            invalidMessage &&
            "border-danger-500 focus:border-danger-500"
          }`}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onInput={onInput}
          onClick={onClick}
          required={required}
          autoComplete={autoComplete}
          onFocus={onFocus}
          autoFocus={autoFocus}
          readOnly={readOnly || disabled}
        />
        <label
          htmlFor={`input-${name}`}
          className={`absolute text-regular-none ${
            disabled ? "text-neutral-400" : "text-neutral-500"
          } duration-300 transform -translate-y-3 scale-75 top-4.5 z-10 origin-[0] ${
            icon && iconPosition === "left" ? "left-12" : "left-4"
          } peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 mb-2 ${labelClassName}`}
        >
          {label ?? placeholder}
        </label>
      </div>
      {!isValid && isInvalid && invalidMessage && (
        <p className="mt-2 text-danger-500 text-small-normal">
          {invalidMessage}
        </p>
      )}
    </>
  );
};

export const InputNormal = ({
  value,
  name,
  type = "text",
  label = "Text Label",
  icon,
  iconPosition = "left",
  placeholder = "Input text",
  className = "",
  onChange,
  onInput,
  onKeyUp,
  onBlur,
  onClick,
  onFocus,
  innerRef,
  zIndex = "",
  readOnly,
  disabled = false,
  defaultValue,
  autoComplete = "off",
  required,
}: Props) => {
  let SpesificIcon;
  if (icon) {
    SpesificIcon = Icon[icon];
  }
  return (
    <div className={`relative w-full md:w-auto ${zIndex}`}>
      {icon && type !== "password" && (
        <div className="flex absolute inset-y-0 items-center left-0 text-neutral-600 pl-3">
          <SpesificIcon size="18" />
        </div>
      )}

      <input
        ref={innerRef}
        id={`input-${name}`}
        value={value}
        defaultValue={defaultValue}
        name={name}
        type={type}
        className={`${className} block rounded-lg !pl-9 py-2 px-3 w-full text-regular-none ${
          disabled || readOnly ? "text-neutral-500" : "text-neutral-900"
        } bg-white focus:!outline-none`}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onInput={onInput}
        onClick={onClick}
        required={required}
        autoComplete={autoComplete}
        onFocus={onFocus}
        readOnly={readOnly || disabled}
      />
    </div>
  );
};

export default Input;
