import { FC, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useRouter } from "next/router";
import useAxios from "../../utils/axiosBuyer";
import { client } from "../../utils/axiosFacility";
import { setCookie } from "cookies-next";
import Button from "../commons/button/Button";
import FacebookLogin from "@greatsumini/react-facebook-login";
import Loading from "../commons/loading/Loading";
import ModalAlert from "../../helpers/ModalAlert";

type Props = {
  endpoint: string;
  redirect: string;
};

const SocialMedia: FC<Props> = ({ endpoint, redirect }) => {
  const axios = useAxios();
  const router = useRouter();
  const [fullLoading, setFullLoading] = useState(false);

  const GoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      const value = {
        type: "google",
        token: tokenResponse.access_token,
      };

      try {
        if (redirect === "/b") {
          const { data } = await axios.post(endpoint, value);
          if (data) {
            setCookie("__AUTH_TOKEN", data, { maxAge: 3600 });
            await axios.get(`/buyer/setting/profile`).then((response) => {
              localStorage.setItem("b/profile", JSON.stringify(response.data));
              setCookie("b/profile/set", true, {
                maxAge: 600,
              });
              setCookie("is_new_buyer", response.data.is_new_buyer);
              window.location.href = "/b";
            });
          }
        } else {
          const { data } = await client.post(endpoint, value);
          if (data) {
            setCookie("__AUTH_TOKEN_FACILITY", data, { maxAge: 3600 });
            await client.get(`/f/setting/profile`).then((response) => {
              localStorage.setItem("f/profile", JSON.stringify(response.data));
              setCookie("f/profile/set", true, {
                maxAge: 600,
              });
              window.location.href = "/f";
            });
          }
        }
      } catch (error: any) {
        ModalAlert({
          title: "Oops!!!",
          message: error?.response?.data.message,
          icon: "error",
        });
      } finally {
        setFullLoading(false);
      }
    },
    onError: (errorResponse: any) => {
      setFullLoading(false);
    },
  });

  const FBLogin = async (response: any) => {
    const value = {
      type: "facebook",
      token: response.accessToken,
    };

    try {
      if (redirect === "/b") {
        const { data } = await axios.post(endpoint, value);
        if (data) {
          setCookie("__AUTH_TOKEN", data, { maxAge: 3600 });
          await axios.get(`/buyer/setting/profile`).then((response) => {
            localStorage.setItem("b/profile", JSON.stringify(response.data));
            setCookie("b/profile/set", true, {
              maxAge: 600,
            });
            setCookie("is_new_buyer", response.data.is_new_buyer);
            window.location.href = "/b";
          });
        }
      } else {
        const { data } = await client.post(endpoint, value);
        if (data) {
          setCookie("__AUTH_TOKEN_FACILITY", data, { maxAge: 3600 });
          await client.get(`/f/setting/profile`).then((response) => {
            localStorage.setItem("f/profile", JSON.stringify(response.data));
            setCookie("f/profile/set", true, {
              maxAge: 600,
            });
            window.location.href = "/f";
          });
        }
      }
    } catch (error: any) {
      ModalAlert({
        title: "Oops!!!",
        message: error?.response?.data.message,
        icon: "error",
      });
    } finally {
      setFullLoading(false);
    }
  };

  return (
    <>
      <Button
        icon="GoogleLight"
        size="lg"
        className="w-full"
        isOutline
        onClick={() => {
          setFullLoading(true);
          GoogleLogin();
          // signIn();
        }}
      >
        Google
      </Button>
      <FacebookLogin
        appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        onSuccess={FBLogin}
        onFail={(error: any) => {
          setFullLoading(false);
          ModalAlert({
            title: "Oops!!!",
            message: "Unsuccessful login with Facebook",
            icon: "error",
          });
        }}
        onProfileSuccess={(response: any) => {
          setFullLoading(false);
        }}
        render={({ onClick }: any) => (
          <Button
            icon="FacebookLight"
            size="lg"
            className="w-full"
            isOutline
            onClick={() => {
              setFullLoading(true);
              onClick();
            }}
          >
            Facebook
          </Button>
        )}
      />
      {fullLoading && <Loading />}
    </>
  );
};

export default SocialMedia;
