import Image from "../image/Image";

export default function Logo(props: any) {
  let src = "/images/logo/logo-new.svg";
  if (props.isLight) {
    src = "/images/logo/logo-light.png";
  }
  return (
    <>
      <Image src={src} alt={props.alt ?? "Logo"} className="w-[8.938rem]" />
    </>
  );
}
